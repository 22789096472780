.paginated-box {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style-type: none;
    padding: 10px 0px;
    /* background-color: #f7f7f7; */
    width: fit-content;
    border-radius: 10px;
    font-size: 0.9rem;
    margin: 0px !important;
  }
  
  .pages-count {
    margin: 0px 6px;
    border-radius: 4px;
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #9D5406;
    /* text-align: center; */
  }
  .ative-page {
    border: 1px solid #F7972D;
  }
  .next-pre-btn {
    font-size: 17px;
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  