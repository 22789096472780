.complaint-prev-navigation {
  color: #AC5C06;
}

.complaint-next-navigation {
  background-color: #AC5C06;
  color: white;
}

.dropdown-item-border {
  border-color: #9CA3AF;
}

.dropdown-inactive-item {
  border-color: #00000066;
  background-color: rgba(0, 0, 0, 0.8);
}
