.resizer {
  position: absolute;
  top: 0;
  height: 100%;
  width: 1px;
  background: white;
  cursor: col-resize;
  user-select: none;
  touch-action: none;
}

.resizer.ltr {
  right: 0;
}

.resizer.isResizing {
  background: white;
  opacity: 1;
}
